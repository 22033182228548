import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css'; // Import your CSS file for styling

const Chat = () => {
    const [messages, setMessages] = useState([
        { role: 'system', content: 'A friendly assistant that provides answers to your questions with Botany Subject.' },
    ]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const chatContainerRef = useRef(null);

    const handleSend = async () => {
        if (input.trim() === '') return; // Prevent sending empty messages

        const newMessages = [...messages, { role: 'user', content: input }];
        setMessages(newMessages);
        setInput('');

        try {
            setIsTyping(true);
            const response = await axios.post('https://botany-gpt.ishavverma.workers.dev/api/run', {
                model: '@hf/thebloke/llama-2-13b-chat-awq',
                input: { messages: newMessages },
            });
            const reply = response.data.result.response;
            setMessages([...newMessages, { role: 'assistant', content: reply }]);
            setIsTyping(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }, [messages]);

    return (
        <div className="app-container">
            <div className="chat-container" ref={chatContainerRef}>
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.role}`}>
                            {msg.content}
                        </div>
                    ))}
                    {isTyping && (
                        <div className="message assistant typing">
                            <span className="dot">Please wait, typing.</span>
                            <span className="dot">.</span>
                            <span className="dot">.</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') handleSend();
                    }}
                />
                <button onClick={handleSend}>Send</button>
            </div>
        </div>
    );
};

export default Chat;
