import React from 'react';
import './App.css';
import Chat from './components/Chat';

const App = () => {
  return (
    <div className="app">
      <div className="app-header">
        <h1>BotanyGPT 🪻</h1>
      </div>
      <div className="app-body">
        <Chat />
      </div>
    </div>
  );
};

export default App;
